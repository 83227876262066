import { IChat, IChatInfo, IChatMessage, ILastMessage } from "../../types/chat"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EMPTY_LAST_MESSAGE } from "../../utils/constants"

interface IActiveChat {
  id: string
  name: string | null | undefined
  userID?: string | null
}

type IState = {
  // для одиночного чата
  allMessage: IChatMessage[]
  chats: IChat[]
  chatsIDs: string[]
  isChatsEnd: boolean
  activeChat: IActiveChat
  isChatsInit: boolean
}

const initialState: IState = {
  allMessage: [],
  chats: [],
  chatsIDs: [],
  isChatsEnd: false,
  activeChat: {
    id: "",
    name: null,
  },
  isChatsInit: false,
}

interface IChatConditions {
  isMessagesLoaded?: boolean
  isVisible?: boolean
  // первоначальный чат при авторизации(первые 14)
  isInitial?: boolean
  id?: string
  name?: string | null
  unreaded_messages?: number
  last_message?: ILastMessage
}

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setAllMessage: (state: any, action: PayloadAction<any>) => {
      state.allMessage = action.payload
    },
    addAllMessage: (state: IState, action: PayloadAction<IChatMessage>) => {
      state.allMessage = [...state.allMessage.filter((f) => f.id !== action.payload.id), action.payload]
    },
    updateReadMessages: (state: any, action: PayloadAction<string[]>) => {
      state.allMessage = [
        ...state.allMessage.map((f: any) => (action?.payload?.includes(f.id) ? { ...f, is_readed: true } : f)),
      ]
    },
    setChatsInit: (state: IState, action: PayloadAction<boolean>) => {
      state.isChatsInit = action.payload
    },
    setChatsEnd: (state: IState, action: PayloadAction<boolean>) => {
      state.isChatsEnd = action.payload
    },
    clearChats: (state: IState) => {
      state.chats = []
      state.chatsIDs = []
      state.activeChat = {
        id: "",
        name: null,
      }
      state.isChatsInit = false
    },
    setChats: (state: IState, action: PayloadAction<IChat[]>) => {
      state.chats = action.payload
      state.chatsIDs = action.payload.map((chat) => chat.id)
    },
    resetToInitialChats: (state: IState) => {
      const filteredChats = state.chats.filter((chat) => chat.isInitial)
      state.chats = filteredChats?.map((chat) => {
        return { ...chat, isVisible: true }
      })
      state.chatsIDs = filteredChats?.map((chat) => chat.id)
    },
    addChats: (state: IState, action: PayloadAction<IChat[]>) => {
      const filteredChats = action.payload.filter((chat) => !state.chatsIDs.includes(chat.id))
      if (filteredChats?.length) {
        state.chats = [...state.chats, ...filteredChats]
        state.chatsIDs = [...state.chatsIDs, ...filteredChats.map((chat) => chat.id)]
      }
    },
    addChat: (state: IState, action: PayloadAction<IChat>) => {
      const filteredChat = state.chatsIDs.includes(action.payload.id)
      if (!filteredChat) {
        state.chats = [...state.chats, action.payload]
        state.chatsIDs = [...state.chatsIDs, action.payload.id]
      }
    },
    addChatToTop: (state: IState, action: PayloadAction<IChat>) => {
      if (action.payload.id === "") {
        state.chats = [action.payload, ...state.chats]
      } else {
        const filteredChat = state.chatsIDs.includes(action.payload.id)
        if (!filteredChat) {
          // если чата нет в локальном списке
          state.chats = [action.payload, ...state.chats]
          state.chatsIDs = [...state.chatsIDs, action.payload.id]
        } else {
          // если чат есть в локальном списке
          state.chats = [action.payload, ...state.chats.filter((chat) => chat.id !== action.payload.id)]
        }
      }
    },
    updateChat: (
      state: IState,
      action: PayloadAction<{ chatID?: string; userID?: string; chatInfo?: IChatInfo; data: IChatConditions }>,
    ) => {
      const { chatID, userID, chatInfo, data } = action.payload
      const existChat = state.chats.some((chat) => {
        return (chatID && chat.id === chatID) || (userID && chat?.user_id === userID)
      })
      if (existChat) {
        // если чат есть в списке
        state.chats = state.chats.map((chat) => {
          if ((chatID && chat.id === chatID) || (userID && chat?.user_id === userID)) {
            // если передали last_message и если этот чат не открыт(активный)
            const countMes = data?.last_message && state.activeChat.id !== chat.id ? 1 : 0
            chat.unreaded_messages = chat.unreaded_messages + countMes
            return { ...chat, ...data }
          }
          return chat
        })
      } else {
        // если чата нет в списке
        if (chatInfo) {
          state.chats = [
            { ...chatInfo, messages: [], isMessagesLoaded: false, isVisible: true, isInitial: true },
            ...state.chats,
          ]
          state.chatsIDs = [...state.chatsIDs, chatInfo.id]
        }
      }
    },
    updateChats: (
      state: IState,
      action: PayloadAction<{ isAll?: boolean; chatIDs?: string[]; userIDs?: string[]; data: IChatConditions }>,
    ) => {
      console.log("updateChats")
      const { isAll, chatIDs, userIDs, data } = action.payload
      state.chats = state.chats.map((chat) => {
        if (
          isAll ||
          (chatIDs?.length && chatIDs.includes(chat.id)) ||
          (userIDs?.length && chat?.user_id && userIDs.includes(chat.user_id))
        ) {
          return { ...chat, ...data }
        }
        return chat
      })
    },
    hideChats: (state: IState) => {
      state.chats = state.chats.map((chat) => {
        return { ...chat, isVisible: false }
      })
    },
    deleteChat: (state: IState, action: PayloadAction<string>) => {
      const idToDelete = action.payload
      if (state.activeChat.id === idToDelete) {
        if (state.chats.length > 1) {
          // если есть еще чаты
          const nextChat = state.chats.filter((chat) => chat.id !== idToDelete)[0]
          state.activeChat = {
            id: nextChat.id,
            name: nextChat.name,
          }
        } else {
          // если это был последний чат
          state.activeChat = {
            id: "",
            name: null,
          }
        }
      }
      state.chats = state.chats.filter((chat) => chat.id !== idToDelete)
      state.chatsIDs = state.chatsIDs.filter((id) => id !== idToDelete)
    },
    setMessagesLoaded: (state: IState, action: PayloadAction<{ chatID: string; isLoaded: boolean }>) => {
      const { chatID, isLoaded } = action.payload
      state.chats = [...state.chats].map((chat) => {
        if (chat.id === chatID) chat.isMessagesLoaded = isLoaded
        return chat
      })
    },
    addMessages: (state: IState, action: PayloadAction<{ chatID: string; messages: IChatMessage[] }>) => {
      const { chatID, messages } = action.payload
      state.chats = state.chats.map((chat) => {
        if (chat.id === chatID) {
          chat.messages = messages
          chat.isMessagesLoaded = true
        }
        return chat
      })
    },
    addMessage: (state: IState, action: PayloadAction<{ chatID: string; message: IChatMessage }>) => {
      const { chatID, message } = action.payload
      const currentChat = state.chats.find((chat) => chat.id === chatID)
      if (currentChat) {
        if (!currentChat.messages.some((mes) => mes.id === message.id)) {
          currentChat.messages.push(message)
        }
        state.chats = [currentChat, ...state.chats.filter((chat) => chat.id !== chatID)]
      }
    },
    changeMessage: (state: IState, action: PayloadAction<{ chatID: string; message: IChatMessage }>) => {
      const { chatID, message } = action.payload
      state.chats = state.chats.map((chat) => {
        if (chat.id === chatID) {
          const messageIndex = chat.messages.findIndex((chatMessage) => chatMessage.id === message.id)
          if (messageIndex >= 0) chat.messages[messageIndex] = message
        }
        if (chat.last_message?.id === message.id) chat.last_message = message
        return chat
      })
    },
    deleteMessage: (
      state: IState,
      action: PayloadAction<{ chatID: string; message: IChatMessage; newLastMessage?: ILastMessage }>,
    ) => {
      const { chatID, message, newLastMessage } = action.payload
      state.chats = state.chats.map((chat) => {
        if (chat.id === chatID) {
          const messagesLength = chat.messages.length
          if (newLastMessage) {
            chat.last_message = newLastMessage
          } else {
            const messageIndex = chat.messages.findIndex((chatMessage) => chatMessage.id === message.id)
            if (messageIndex >= 0 && messageIndex === messagesLength - 1) {
              // если удаляется последнее сообщение
              if (messagesLength > 2) {
                // если есть предыдущее сообщение
                chat.last_message = chat.messages[messageIndex - 1]
              } else {
                // если нет предыдущего сообщения. То есть, было всего 1 сообщение в списке messages
                chat.last_message = EMPTY_LAST_MESSAGE
              }
            }
          }
          // удаляем сообщение из списка сообщений
          chat.messages = chat.messages.filter((chatMessage) => chatMessage.id !== message.id)
        }
        return chat
      })
    },
    setActiveChat: (state: IState, action: PayloadAction<IActiveChat>) => {
      state.activeChat = action.payload
    },
  },
})

export const {
  setAllMessage,
  addAllMessage,
  updateReadMessages,
  setChatsInit,
  setChatsEnd,
  clearChats,
  setChats,
  resetToInitialChats,
  addChats,
  addChat,
  addChatToTop,
  updateChat,
  updateChats,
  hideChats,
  deleteChat,
  setMessagesLoaded,
  addMessages,
  addMessage,
  changeMessage,
  deleteMessage,
  setActiveChat,
} = chatSlice.actions

export const selectChat = (state: any): IState => state[chatSlice.name]
