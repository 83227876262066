import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import IconBtn from "../../Assets/IconBtn/IconBtn"
import OrderDescription from "../../OrderDescription/OrderDescription"
import Button from "../../Assets/Button/Button"
import { useTranslation } from "react-i18next"
import OrderCallbackModal from "../OrderInfoModal/OrderCallbackModal"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import clsx from "clsx"
import Chat from "../../Chat/Chat"
import {
  addChats,
  addChatToTop,
  addMessages,
  hideChats,
  resetToInitialChats,
  selectChat,
  setActiveChat,
  setChats,
  updateChat,
  updateChats,
} from "../../../redux/slice/chat"
import { useDispatch } from "react-redux"
import Search from "../../Search/Search"
import MessagesAsideList from "./MessagesAsideList"
import _debounce from "lodash/debounce"
import MessagesModalUsers from "./MessagesModalUsers"
import {
  useCreateChatMutation,
  useLazyGetChatListQuery,
  useLazyGetChatMessagesQuery,
  useLazyGetUserChatQuery,
  useSendChatMessageMutation,
} from "../../../redux/api/chat"
import { useLazyGetServiceOrderQuery } from "../../../redux/api/content"
import { IServiceOrder } from "../../../types/orderTypes"
import { useAppSelector } from "../../../hooks"
import useWindowSize from "../../../hooks/useWindowSize"
import TitleBack from "../../TitleBack/TitleBack"
import { setMobileMenuIsHidden } from "../../../redux/slice/isMoreModal"
import { getMessagesModalFirstChat, setMessagesModalFirstChat } from "../../../redux/slice/modals"
import MessagesModalLoader from "./MessagesModalLoader/MessagesModalLoader"
import { useNavigate } from "react-router-dom"
import { EMPTY_LAST_MESSAGE, LIMIT_CHATS_COUNT } from "../../../utils/constants"
import styles from "./MessagesModal.module.scss"

interface Props {
  open: boolean
  setOpen: (res?: boolean) => void
}

const MessagesModal: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isDesktop } = useWindowSize()
  const { allowScroll } = useScrollBlock()

  const loaderRef = useRef<HTMLDivElement>(null)
  const isFirstLoadMess = useRef<any>(false)

  const currentFirstChat = useAppSelector(getMessagesModalFirstChat)
  const { chats, isChatsEnd, activeChat } = useAppSelector(selectChat)

  const [getChatMessages] = useLazyGetChatMessagesQuery()
  const [sendChatMessage] = useSendChatMessageMutation()
  const [getChatList, { isFetching, isLoading }] = useLazyGetChatListQuery()
  const [getOrder] = useLazyGetServiceOrderQuery()
  const [createChat] = useCreateChatMutation()
  const [getUserChat] = useLazyGetUserChatQuery()

  const [isInfoOpen, setInfoOpen] = useState<boolean>(false)
  const [isCanceledModal, setCanceledModal] = useState<boolean>(false)

  const [loadingChatList, setLoadingChatList] = useState<boolean>(true)
  const [loadingChat, setLoadingChat] = useState<boolean>(true)
  const [loadingOrderInfo, setLoadingOrderInfo] = useState<boolean>(true)

  const [searchChat, setSearchChat] = useState<string>("")
  const [order, setOrder] = useState<IServiceOrder | null>(null)
  const [activeOrderId, setActiveOrderId] = useState<string | null>("")
  const [offset, setOffset] = useState<number>(LIMIT_CHATS_COUNT)
  const [isAllChats, setAllChats] = useState<boolean>(false)
  const [unreadMessList, setUnreadMessList] = useState<string[]>([])
  const [isFirstNoChats, setFirstNoChats] = useState<boolean>(false)
  const [isOpenChatOnMobile, setIsOpenChatOnMobile] = useState<boolean>(false)
  const [dummyChats, setDummyChats] = useState<boolean>(false)

  const isViewChat = useMemo((): boolean => {
    if (isDesktop || (!isDesktop && isOpenChatOnMobile) || currentFirstChat) return true
    return false
  }, [isDesktop, isOpenChatOnMobile])

  const setInitialChats = async () => {
    if (!chats?.length) {
      setAllChats(true)
      setFirstNoChats(true)

      setDummyChats(true)
      return
    }
    const firstChat = chats[0]
    if (currentFirstChat) {
      setIsOpenChatOnMobile(true)
      dispatch(setMessagesModalFirstChat(null))
    }

    dispatch(
      setActiveChat({
        id: firstChat.id,
        name: firstChat.name,
      }),
    )
    setActiveOrderId(firstChat.order_id)
    setLoadingChatList(false)
    setLoadingChat(false)
  }

  useEffect(() => {
    if (!open) {
      setInfoOpen(false)
      setLoadingChat(true)
      return
    }
    void setInitialChats()

    return () => {
      allowScroll(true)
    }
  }, [open])

  useEffect(() => {
    if (!isDesktop) {
      dispatch(setMobileMenuIsHidden(isViewChat))
    }
  }, [isViewChat])

  useEffect(() => {
    if (!unreadMessList.length) return
    const currentChat = chats.find((i) => i.id === activeChat.id)
    if (!currentChat?.unreaded_messages) return
    dispatch(updateChat({ chatID: activeChat.id, data: { unreaded_messages: 0 } }))
  }, [unreadMessList, activeChat.id])

  // Очищаем список id прочитанных сообщений, при смене чата
  useEffect(() => {
    setUnreadMessList([])
  }, [activeChat.id])

  const debounceFn = useCallback(
    _debounce((str: string) => {
      if (!isFirstLoadMess.current) return
      if (!str?.length) {
        dispatch(resetToInitialChats())
        setLoadingChatList(false)
        setOffset(LIMIT_CHATS_COUNT)
        setAllChats(isChatsEnd)
        return
      }
      dispatch(hideChats())
      setLoadingChatList(true)
      getChatList({ search: str, limit: LIMIT_CHATS_COUNT, offset: 0 }).then(({ data }) => {
        const dataChats = data?.aDialogs
        if (dataChats?.length) {
          dispatch(
            addChats(
              dataChats?.map((dialog) => {
                return { ...dialog, messages: [], isMessagesLoaded: false, isVisible: true }
              }),
            ),
          )
          dispatch(
            updateChats({
              chatIDs: dataChats.map((dialog) => dialog.id),
              isAll: !str?.length,
              data: {
                isVisible: true,
              },
            }),
          )
        }
        setLoadingChatList(false)
        setOffset(LIMIT_CHATS_COUNT)
        setAllChats(data?.bIsEnd || false)
      })
    }, 500),
    [],
  )

  useEffect(() => {
    if (!isFirstLoadMess?.current) {
      isFirstLoadMess.current = true
      return
    }
    debounceFn(searchChat)
  }, [searchChat, isFirstLoadMess])

  const callbackSearch = () => {
    if (isAllChats) return
    setLoadingChatList(true)
    getChatList({ search: searchChat, limit: LIMIT_CHATS_COUNT, offset: offset }).then(({ data }) => {
      if (!data?.aDialogs) {
        setLoadingChatList(false)
        setAllChats(true)
        return
      }
      dispatch(
        addChats(
          data.aDialogs.map((dialog) => {
            return { ...dialog, messages: [], isMessagesLoaded: false, isVisible: true }
          }),
        ),
      )
      setLoadingChatList(false)
      setAllChats(data?.bIsEnd || false)
      setOffset((prev) => prev + LIMIT_CHATS_COUNT)
    })
  }

  const clickChat = (chatId: string, name?: string | null, userID?: string | null) => {
    setInfoOpen(false)
    const currentChat = chats.find((i) => {
      if (!chatId) return i.user_id === userID
      return i.id === chatId
    })
    dispatch(
      setActiveChat({
        id: chatId,
        name: currentChat?.name || name,
        userID: userID || activeChat?.userID || null,
      }),
    )
    setActiveOrderId(currentChat?.order_id || null)
    // если чат есть в списке, но сообщения в нём еще не были загружены
    if (currentChat && !currentChat.isMessagesLoaded) {
      setLoadingChat(true)
      getChatMessages(chatId).then((res) => {
        if (res?.data?.aMessages) {
          dispatch(addMessages({ chatID: chatId, messages: res.data.aMessages }))
          setLoadingChat(false)
        }
      })
    }
    // если чата еще нет в списке
    if (!currentChat) {
      if (chatId) {
        // чат есть в базе
        setLoadingChat(true)
        getChatMessages(chatId).then((res) => {
          if (res?.data?.aMessages) {
            dispatch(addMessages({ chatID: chatId, messages: res.data.aMessages }))
            setLoadingChat(false)
          }
        })
      } else {
        setLoadingChat(false)
      }
    }
    setDummyChats(false)
  }

  const toggleInfoOrder = () => {
    const currentChat = chats.find((i) => i.id === activeChat.id)
    if (!currentChat?.order_id) return
    setLoadingOrderInfo(true)
    setInfoOpen((prev) => !prev)
    getOrder(currentChat.order_id).then(({ data }) => {
      if (data) setOrder(data)
      setLoadingOrderInfo(false)
    })
  }

  // при клике на юзера в шапке модалки, где выводиться весь список пользователей
  const handlerClickUser = (userID: string, name: string, avatar_id?: string) => {
    if (!userID || !name) return
    getUserChat(userID)
      .unwrap()
      .then((res) => {
        if (res?.id) {
          // чат существует в базе
          const existedChat = chats.find((i) => i.id === res.id)
          if (existedChat) {
            // если чат есть уже в локальном списке, то просто открываем его
            dispatch(setChats([existedChat, ...chats.filter((item) => item.id !== existedChat.id)]))
            clickChat(existedChat.id)
          } else {
            // если чата еще нет в локальном списке
            dispatch(addChatToTop({ ...res, messages: [], isMessagesLoaded: false, isVisible: true }))
            clickChat(res.id, res.name)
          }
        } else {
          // чата еще нет в базе
          const existedChat = chats.find((i) => i.user_id === userID)
          if (!existedChat) {
            // если чата еще нет в локальном списке, то добавляем его шаблон
            const dummyChat = {
              id: "",
              image: avatar_id || "",
              is_private: true,
              last_message: EMPTY_LAST_MESSAGE,
              name: name,
              order_id: null,
              user_id: userID,
              unreaded_messages: 0,
              messages: [],
              isMessagesLoaded: true,
              isVisible: true,
            }
            dispatch(addChatToTop(dummyChat))
          }
          clickChat("", name, userID)
        }
      })
      .catch((e) => {
        console.error("catch error in getUserChat: ", e)
      })
  }

  // при отправке сообщения в чате, который еще не создан. Таким образом, мы создаём новый чат, а потом делаем его активным
  const handleCreateNewChat = (mesData: any, mes: any, userID?: string, userName?: string) => {
    if (!userID) return
    // проверка, есть ли этот чат в локальном списке
    const curChat = chats.find((chat) => chat.user_id === userID)
    if (!curChat) return
    createChat({ type: "users", id: userID })
      .unwrap()
      .then((res) => {
        if (!res) return
        dispatch(updateChat({ userID: userID, data: { id: res.id, last_message: mesData, isInitial: true } }))
        sendChatMessage({ ...mes, id: res.id }).then(() => {
          clickChat(res.id)
        })
      })
      .catch((er) => {
        console.error("error in createChat: ", er)
      })
  }

  return (
    <>
      <ModalPortal
        isOpen={open}
        setIsOpen={setOpen}
        className={clsx(styles.modal, {
          [styles["modal-mobile"]]: !isDesktop,
          [styles["mobile-chat__opened"]]: isViewChat && !isDesktop,
        })}
        isCloseBtn={isDesktop}
        header={
          <>
            {!isDesktop ? (
              <div className={styles["header-container"]}>
                <TitleBack
                  title={isViewChat ? activeChat.name ?? t("chats") : t("chats")}
                  onClick={() => {
                    if (isViewChat && !isInfoOpen) {
                      console.log("click1")
                      setIsOpenChatOnMobile(false)
                      return
                    }
                    if (isInfoOpen) {
                      setInfoOpen(false)
                      return
                    }
                    navigate(-1)
                    setOpen(false)
                  }}
                  noLinkBack
                />

                {activeOrderId && isViewChat && !isInfoOpen && (
                  <IconBtn
                    icon={"info"}
                    borderSize={"circle"}
                    mode={"white"}
                    className={clsx(styles.mobileBtnInfo)}
                    onClick={toggleInfoOrder}
                  />
                )}
              </div>
            ) : (
              <>
                <div className={clsx(styles.headerSlice, styles.headerSliceAside)}>
                  <h2 className={"modal__title"}>{t("chats")}</h2>
                  <MessagesModalUsers className={styles.headerChatWrap} onClick={handlerClickUser} />
                </div>
                <div
                  className={clsx(
                    styles.headerSlice,
                    styles.headerSliceMain,
                    isInfoOpen && styles["headerSliceMain--is-info-open"],
                  )}
                >
                  <h2 className={clsx("modal__title", styles.titleName)}>{activeChat?.name}</h2>
                  {activeOrderId && (
                    <IconBtn
                      icon={"close-square"}
                      borderSize={"sm"}
                      mode={"gray"}
                      size={"sm"}
                      className={clsx(styles.btnInfo, isInfoOpen && styles["btnInfo--is-open"])}
                      onClick={toggleInfoOrder}
                    />
                  )}
                  <div className={styles.separator} />
                </div>
              </>
            )}
          </>
        }
        name="MessagesModal"
      >
        <div className={styles.main}>
          <aside className={styles.aside}>
            <Search searchVal={searchChat} setSearchVal={setSearchChat} className={styles.search} />
            {chats?.some((chat) => chat.isVisible) || isLoading || isFetching ? (
              <>
                <MessagesAsideList
                  list={chats?.filter((chat) => chat.isVisible)}
                  activeChat={activeChat}
                  isLoading={loadingChatList}
                  callbackSearch={callbackSearch}
                  onClick={(chatId, name, userID) => {
                    clickChat(chatId, name, userID)
                    if (!isDesktop) {
                      setIsOpenChatOnMobile(true)
                    }
                  }}
                  isAllChats={isAllChats}
                />
              </>
            ) : searchChat && !isFetching && !chats.some((chat) => chat.isVisible) ? (
              <p className={styles.noChatList}>{t("objNotFound")}</p>
            ) : isFirstNoChats ? (
              <p className={styles.noChatList}>{t("noChatsYet")}</p>
            ) : null}
            <div ref={loaderRef} />
          </aside>

          {dummyChats ? (
            <div className={styles.dummyChat} />
          ) : (
            <>
              {isViewChat && (
                <>
                  {loadingChat ? (
                    <MessagesModalLoader />
                  ) : (
                    <Chat
                      className={styles.chat}
                      chatID={activeChat.id}
                      orderInfo={activeChat}
                      isFirstLoadMess={isFirstLoadMess.current}
                      setUnreadMessList={setUnreadMessList}
                      emptyTxt={t(activeOrderId ? "emptyTxtChatOrder" : "nothingYet")}
                      handleCreateNewChat={handleCreateNewChat}
                      isChatList
                    />
                  )}
                </>
              )}
            </>
          )}

          {isInfoOpen && (
            <div className={clsx(styles.info, { [styles["mobile-info"]]: !isDesktop })}>
              {loadingOrderInfo ? (
                <div className={clsx("skeletonBlock", styles.orderLoader)} />
              ) : (
                <>
                  {order && (
                    <>
                      <OrderDescription order={order} layout={"history"} />
                      <footer className={styles.infoFooter}>
                        {!isDesktop && (
                          <Button
                            txt={t("back")}
                            type="button"
                            onClick={() => {
                              setInfoOpen(false)
                            }}
                            className={`btn btn_grey ${styles["back-btn"]}`}
                          />
                        )}

                        <Button
                          txt={order.status === 0 ? t("cancel") : t("returnToWork")}
                          mode={"warning"}
                          size={"sm"}
                          onClick={() => {
                            setCanceledModal(true)
                            setOrder((prev) => (!prev ? null : { ...prev, status: order.status === 0 ? -1 : 0 }))
                          }}
                        />
                      </footer>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </ModalPortal>

      {isCanceledModal && order?.id && (
        <OrderCallbackModal
          layout={order.status === 0 ? "active" : "history"}
          open={isCanceledModal}
          setOpen={setCanceledModal}
          orderId={order.id}
          callbackClose={() => {
            setOpen(false)
            allowScroll(true)
          }}
          className={!isDesktop && styles["callback-modal"]}
        />
      )}
    </>
  )
}

export default MessagesModal
